.root {
  padding: 0 20px;
  position: relative;
}

.swiper {
  padding: 0 20px;
}

.swiperBtnBig {
  @apply absolute cursor-pointer z-10 top-40 text-base-border transition-colors hover:text-brand-hover;
}

.swiperBtnSmall {
  @apply absolute cursor-pointer z-10 top-0 text-grayText bg-cardImg;
  width: 30px;
  height: 30px;
  padding: 2px 3px;
}

.disabledBig,
.disabledBig:hover {
  @apply cursor-default text-grayBg;
}

.disabledSmall,
.disabledSmall:hover {
  @apply cursor-default opacity-60;
}

@media (max-width: 1024px) {
  .root,
  .swiper {
    padding: 0 !important;
  }
}
