.root {
  @apply relative shadow-none transition-all rounded-brand flex flex-col justify-between border py-4 sm:border-0 sm:border-base-border;
  box-shadow: none;
  padding-inline: 15px;
  @media (min-width: 640px) {
    padding: 0px;
  }

  & .centralButton {
    @apply opacity-0;
  }
  
  .addToCart {
    @apply py-4 rounded-brand sm:rounded-t-none opacity-100 sm:opacity-0 uppercase font-normal sm:font-semibold text-base inline-flex w-full border-0 rounded-b-brand sm:py-5 lg:pl-10 pl-4 pr-4 lg:pr-10 sm:bg-productCardButton bg-mobileProductCardButton;
    color: #ffffff;
    line-height: 22px;
    
  }
}

.root:hover {
  @media (min-width: 640px) {
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.25);
  }

  & .addToCart,
  & .centralButton {
    @apply opacity-100;
  }
  & img.productImage {
    @apply opacity-60;
  }
}

.content {
  @apply flex w-full flex-col;
}
.imageContainer {
  height: 171px;
  @apply w-full relative flex justify-center overflow-hidden sm:h-[271px];
  box-sizing: border-box;
}

img.productImage {
  @apply h-full ;
  @media (min-width: 640px) {
    height: 220px;
  }
}

.name {
  @apply text-base font-semibold uppercase w-full px-3 mx-auto mt-4 sm:mt-5 mb-1 sm:mb-2;
  min-height: 24px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: var(--brand);
}

.description {
  @apply text-sm font-semibold sm:font-normal text-center w-full px-3 mb-4 sm:mb-5;
  color: #000;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.priceWrapper {
  @apply flex w-full items-center justify-center flex-row flex-wrap mb-4 sm:mb-5;
}

.price {
  @apply text-2xl font-bold mr-2.5;
  color: var(--brand);
}

.oldPrice {
  @apply text-sm font-semibold line-through text-center;
  color: var(--compageAtColor);
}

.addToCart {
  @apply py-4 rounded-brand sm:rounded-t-none opacity-100 min-h-[62px] sm:opacity-0 font-bold uppercase text-base inline-flex w-full border-0 rounded-b-brand sm:py-5 lg:pl-10 pl-4 pr-4 lg:pr-10 sm:bg-productCardButton bg-mobileProductCardButton;
  color: #ffffff;
  line-height: 22px;
  
}

.addToCart:hover {
  @apply bg-hoverProductCardButton
}

.wishlistButton {
  @apply absolute top-9 right-9 sm:top-2 sm:right-2 bg-transparent transition-all;
}

.wishlistButton:hover {
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 100%;
}
.centralButton {
  @apply absolute top-1/2 left-1/2 bg-white text-sm border rounded-brand z-20;
  width: 177px;
  padding: 14px 0;
  transform: translate(-50%, -50%);
  color: var(--brand);
}
